import React, { useState, Suspense } from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import {createLinkFormatter} from './TableUtils.js';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

const getFileRecordProperties = async (rawFileRecordId) => {
  const url = (process.env.NODE_ENV === 'development' ? '/raw_file_record_properties.json' : '/raw_file_record_properties') + '?raw_file_record_id=' + rawFileRecordId;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

const getTransactionRecordProperties = async (rawFileRecordId) => {
  const url = (process.env.NODE_ENV === 'development' ? '/raw_transaction_record_properties.json' : '/raw_transaction_record_properties') + '?raw_file_record_id=' + rawFileRecordId;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

const getTransactionRecords = async (rawFileRecordId) => {
  const url = (process.env.NODE_ENV === 'development' ? '/raw_transaction_records.json' : '/raw_transaction_records') + '?raw_file_record_id=' + rawFileRecordId;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

export const loader = async ({ params }) => {
  const rawFileRecordId = params.rawFileRecordId;
  const transactionRecords = getTransactionRecords(rawFileRecordId);
  const fileRecordProperties = getFileRecordProperties(rawFileRecordId);
  const transactionRecordProperties = getTransactionRecordProperties(rawFileRecordId);
  return defer({transactionRecords, fileRecordProperties, transactionRecordProperties});
};

function TransactionFileDetail() {
  const loaderData = useLoaderData();
  
  const [loading, setLoading] = useState(false);
  const [createProcess, setCreateProcess] = useState(null);
  const [transaction, setTransaction] = useState(null);

  const propsColumns = [
    { title: "key", field: "key", responsive: 0, },
    { title: 'value', field: 'value', responsive: 0, },
  ];
  
  const createPropertiesTableFormatter = (properties) => {
    return (cell, formatterParams, onRendered) => {
      const raw_transaction_record_id = cell.getRow().getData().raw_transaction_record_id;
      
      const holder = document.createElement("div");
      const table = document.createElement("table");
      holder.style.boxSizing = "border-box";
      holder.style.padding = "10px 30px 10px 10px";
      holder.style.borderTop = "1px solid #333";
      holder.style.borderBotom = "1px solid #333";
      table.style.border = "1px solid #333";
      holder.appendChild(table);
      
      properties.forEach((row) => {
        if (row['raw_transaction_record_id'] === raw_transaction_record_id) {
          const tr = document.createElement("tr");
          const td1 = document.createElement("td");
          const td2 = document.createElement("td");
          td1.innerHTML = row['key'];
          td2.innerHTML = row['value'];
          tr.appendChild(td1);
          tr.appendChild(td2);
          table.appendChild(tr);
        }
      });
      
      return table;
    };
  }

  const all = Promise.all([loaderData.transactionRecords, loaderData.transactionRecordProperties]);

  const renderTxns = () => {
    return <Suspense fallback={<LoadingScreen visible="true" />}>
             <Await resolve={all}
                    errorElement={<div>Error Loading</div>}>
               {(all) => (
                 <ReactTabulator
                   options={{
                     data: all[0],
                     initalSort: [
                       {column: "day", dir: "desc"},
                       {column:"raw_transaction_record_id", dir: "desc"},
                     ],
                   }}
                   columns={[
                     { title: "raw_transaction_record_id", field: "raw_transaction_record_id", responsive: 0, formatter: createLinkFormatter(), cellClick:function(e, cell) { setTransaction(cell.getRow().getData().raw_transaction_record_id) }, },
                     { title: 'day', field: 'day', responsive: 0 },
                     { title: 'create_process', field: 'create_process', responsive: 0, formatter: createLinkFormatter(), cellClick:function(e, cell) { setCreateProcess(cell.getRow().getData().create_process);},  },
                     { title: 'properties', field: 'properties', responsive: 0 , formatter: createPropertiesTableFormatter(all[1]), },
                     { title: "update_date", field: "update_date", responsive: 3, },
                     { title: "create_date", field: "create_date", responsive: 4, },
                     { title: 'raw_file_record_id', field: 'raw_file_record_id', responsive: 2 },
                   ]}
                 />
               )}
             </Await>
           </Suspense>;
  };

  return (
    createProcess ? <Navigate to={`/parsing/${createProcess}`} /> :
    transaction ? <Navigate to={`/transactions?id=${transaction}|`} /> :
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.fileRecordProperties}
             errorElement={<div>Error Loading</div>}>
        {(fileRecordProperties) => (
          <div className="transaction-file-detail">
            <LoadingScreen visible={loading} />
            <ReactTabulator
              options={{
                data: fileRecordProperties,
                initialSort: [
                  {column:"key", dir: "asc"},
                ],
              }}
              columns={propsColumns}
            />
            { renderTxns() }
          </div>
        )}
      </Await>
    </Suspense>
  );
}

export default TransactionFileDetail;
