import React, { useState, Suspense } from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import {createLinkFormatter, createNumberFormatter} from './TableUtils.js';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

const treeify = (flat) => {
  var dict = {};
  flat.forEach((row) => {
    if (row['path_prefix'] === '') {
      row['path_prefix'] = '/';
    } else {
      row['parent_path_prefix'] = row['path_prefix'].substring(0, row['path_prefix'].lastIndexOf('/'));
      if (row['parent_path_prefix'] === '') {
        row['parent_path_prefix'] = '/';
      }
    }
    row['path'] = row['path_prefix'].substring(row['path_prefix'].lastIndexOf('/') + 1);
    
    dict[row['path_prefix']] = row;
  });
  flat.forEach((row) => {
    var parent = dict[row['parent_path_prefix']];
    if (parent) {
      if (!parent['_children']) {
        parent['_children'] = [];
      }
      parent['_children'].push(row);
    }
  });
  return [dict['/'], ];
};

const getPathPrefixes = async() => {
  const url = (process.env.NODE_ENV === 'development' ? '/raw_file_record_path_prefixes.json' : '/raw_file_record_path_prefixes');
  return fetch(url).then((res) => res.json()).then((res) => treeify(res)).catch((e) => {console.error(e);});
};

export const loader = async ({ params }) => {
  const pathDataPromise = getPathPrefixes();
  return defer({pathData: pathDataPromise});
};

function TransactionFilePathChooser() {
  const loaderData = useLoaderData();
  
  const [loading, setLoading] = useState(false);
  const [pathPrefix, setPathPrefix] = useState(null);

  const columns = [
    { title: "path", field: "path", responsive: 0, formatter: createLinkFormatter(), cellClick:function(e, cell) { setPathPrefix( cell.getRow().getData().path_prefix );  }},
    { title: 'n', field: 'n', responsive: 0, hozAlign:'right', formatter: createNumberFormatter(0), },
  ];
  
  return (
    pathPrefix ?  <Navigate to={`/files/${encodeURIComponent(pathPrefix)}`} /> :
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.pathData}
             errorElement={<div>Error Loading</div>}>
        {(pathData) => (
          <div className="transaction-file-path-chooser">
            <LoadingScreen visible={loading} />
            <ReactTabulator
              options={{
                data: pathData,
                dataTree:true,
                dataTreeStartExpanded: true,
                dataTreeBranchElement: true,
                dataTreeChildIndent: 15,
              }}
              columns={columns}
            />
          </div>
        )}
      </Await>
    </Suspense>      
  );
}

export default TransactionFilePathChooser;
