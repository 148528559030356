import React, { useState, Suspense } from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import {createLinkFormatter} from './TableUtils.js';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

const getFilesByPathPrefix = async (pathPrefix) => {
  const url = (process.env.NODE_ENV === 'development' ? '/raw_file_records.json' : '/raw_file_records') + '?path_prefix=' + decodeURIComponent(pathPrefix);
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

export const loader = async ({ params }) => {
  const pathPrefix = params.pathPrefix;
  const pathDataPromise = getFilesByPathPrefix(pathPrefix);
  return defer({pathData: pathDataPromise});
};

function TransactionFileChooser() {
  const loaderData = useLoaderData();

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [createProcess, setCreateProcess] = useState(null);

  const columns = [
    { title: "filename", field: "filename", responsive: 0, formatter: createLinkFormatter(), cellClick:function(e, cell) { setFile( cell.getRow().getData()['raw_file_record_id']); }},
    { title: 'create_date', field: 'create_date', responsive: 0, },
    { title: 'raw_file_record_id', field: 'raw_file_record_id', responsive: 2, },
    { title: 'create_process', field: 'create_process', responsive: 3, cellClick:function(e, cell) { setCreateProcess(cell.getRow().getData().create_process); }},
  ];

  return (
    file ? <Navigate to={`/file/${file}`} /> :
    createProcess ? <Navigate to={`/parsing/${createProcess}`} /> :
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.pathData}
             errorElement={<div>Error Loading</div>}>
        {(pathData) => (
          <div className="transaction-file-chooser">
            <LoadingScreen visible={loading} />
            <ReactTabulator
              options={{
                data: pathData,
                initialSort: [
                  {column: "create_date", dir: "desc"},
                ],
              }}
              columns={columns}
            />
          </div>
        )}
      </Await>
    </Suspense>
  );
}

export default TransactionFileChooser;
