import React, { useState, Suspense } from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

import {createLinkFormatter} from './TableUtils.js';

const getTransactionTranslations = async (createProcess) => {
  const url = (process.env.NODE_ENV === 'development' ? '/transaction_translations.json?create_process=' : '/transaction_translations?create_process=') + createProcess;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

const getTransactionTranslationRules = async (createProcess) => {
  const url = (process.env.NODE_ENV === 'development' ? '/transaction_translation_rules.json?create_process=' : '/transaction_translation_rules?create_process=') + createProcess;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

const getTransactionTranslationFieldTranslations = async (createProcess) => {
  const url = (process.env.NODE_ENV === 'development' ? '/transaction_translation_field_translations.json?create_process=' : '/transaction_translation_field_translations?create_process=') + createProcess;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

const getTransactionTranslationFieldTranslationEntries = async (createProcess) => {
  const url = (process.env.NODE_ENV === 'development' ? '/transaction_translation_field_translation_entries.json?create_process=' : '/transaction_translation_field_translation_entries?create_process=') + createProcess;
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
};

export const loader = async ({ params }) => {
  const createProcess = params.createProcess;
  const transactionTranslations = getTransactionTranslations(createProcess);
  const transactionTranslationRules = getTransactionTranslationRules(createProcess);
  const transactionTranslationFieldTranslations = getTransactionTranslationFieldTranslations(createProcess);
  const transactionTranslationFieldTranslationEntries = getTransactionTranslationFieldTranslationEntries(createProcess);
  return defer({transactionTranslations, transactionTranslationRules, transactionTranslationFieldTranslations, transactionTranslationFieldTranslationEntries});
};

function ParsingConfigCreateProcess() {
  const loaderData = useLoaderData();
  
  const [loading, setLoading] = useState(false);
  //const [transactionTranslationFieldTranslationId, setTransactionTranslationFieldTranslationId] = useState(null);
  const [transactionTranslationId, setTransactionTranslationId] = useState(null);

  const createRulesTableFormatter = (rules) => {
    return (cell, formatterParams, onRendered) => {
      const transaction_translation_id = cell.getRow().getData().transaction_translation_id;

      const holder = document.createElement("div");
      const table = document.createElement("table");
      holder.style.boxSizing = "border-box";
      holder.style.padding = "10px 30px 10px 10px";
      holder.style.borderTop = "1px solid #333";
      holder.style.borderBotom = "1px solid #333";
      table.style.border = "1px solid #333";
      holder.appendChild(table);

      rules.forEach((row) => {
        if (row['transaction_translation_id'] === transaction_translation_id) {
          const tr = document.createElement("tr");
          const td1 = document.createElement("td");
          const td2 = document.createElement("td");
          td1.innerHTML = row['field'];
          td2.innerHTML = row['regex'];
          tr.appendChild(td1);
          tr.appendChild(td2);
          table.appendChild(tr);
        }
      });

      return table;
    };
  }

  const createEntriesTableFormatter = (entries) => {
    return (cell, formatterParams, onRendered) => {
      const transaction_translation_field_translation_id = cell.getRow().getData().transaction_translation_field_translation_id;

      const holder = document.createElement("div");
      const table = document.createElement("table");
      holder.style.boxSizing = "border-box";
      holder.style.padding = "10px 30px 10px 10px";
      holder.style.borderTop = "1px solid #333";
      holder.style.borderBotom = "1px solid #333";
      table.style.border = "1px solid #333";
      holder.appendChild(table);

      entries.forEach((row) => {
        if (row['transaction_translation_field_translation_id'] === transaction_translation_field_translation_id) {
          const tr = document.createElement("tr");
          const td1 = document.createElement("td");
          const td2 = document.createElement("td");
          const td3 = document.createElement("td");
          td1.innerHTML = row['priority'];
          td2.innerHTML = row['regex'];
          td3.innerHTML = row['output_value_format'];
          tr.appendChild(td1);
          tr.appendChild(td2);
          tr.appendChild(td3);
          table.appendChild(tr);
        }
      });

      return table;
    };
  }

  const all = Promise.all([loaderData.transactionTranslations, loaderData.transactionTranslationRules, loaderData.transactionTranslationFieldTranslations, loaderData.transactionTranslationFieldTranslationEntries]);
  
  const createTransactionTranslationColumns = (rules) => [
    { title: "priortity", field: "priority", responsive: 0, },
    { title: 'rules', field: 'rules', responsive: 0 , formatter: createRulesTableFormatter(rules), },
    { title: "transaction_translation_id", field: "transaction_translation_id", responsive: 0, formatter: createLinkFormatter(), cellClick:function(e, cell) { setTransactionTranslationId(cell.getRow().getData().transaction_translation_id) }, },
    { title: "transaction_type", field: "transaction_type", responsive: 0, },
    { title: "day_format", field: "day_format", responsive: 0, },
    { title: "from_account_format", field: "from_account_format", responsive: 0, },
    { title: "to_account_format", field: "to_account_format", responsive: 0, },
    { title: "from_symbol_format", field: "from_symbol_format", responsive: 0, },
    { title: "to_symbol_format", field: "to_symbol_format", responsive: 0, },
    { title: "from_quantity_format", field: "from_quantity_format", responsive: 0, },
    { title: "to_quantity_format", field: "to_quantity_format", responsive: 0, },
    { title: "price_format", field: "price_format", responsive: 0, },
    { title: "note_format", field: "note_format", responsive: 0, },
    { title: "recording_account_format", field: "recording_account_format", responsive: 0, },
    { title: "unique_tag_format", field: "unique_tag_format", responsive: 0, },
    //{ title: "create_process", field: "create_process", responsive: 2, formatter: createLinkFormatter(),  },
    //{ title: "create_date", field: "create_date", responsive: 3, },
    //{ title: "update_date", field: "update_date", responsive: 4, },
  ];

  const createTransactionTranslationFieldTranslationColumns = (entries) => [
    { title: "output_field_key", field: "output_field_key", responsive: 0, /*formatter: createLinkFormatter(), cellClick:function(e, cell) { setTransactionTranslationFieldTranslationId(cell.getRow().getData().transaction_translation_field_translation_id)}, */ },
    { title: "input_field_format", field: "input_field_format", responsive: 0, },
    { title: "entries", field: "entries", responsive: 0, formatter: createEntriesTableFormatter(entries), }
    //{ title: "create_process", field: "create_process", responsive: 2,  },
    //{ title: "create_date", field: "create_date", responsive: 2, },
    //{ title: "update_date", field: "update_date", responsive: 3, },
  ];
  
  return (
    //transactionTranslationFieldTranslationId ? <Navigate to={`/parsing/field/${transactionTranslationFieldTranslationId}`} /> :
    transactionTranslationId ? <Navigate to={`/transactions?id=|${transactionTranslationId}`} /> :
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={all}
             errorElement={<div>Error Loading</div>}>
        {(all) => (
          <div className="parsing-config-transaction-translations">
            <LoadingScreen visible={loading} />
            <ReactTabulator
              options={{
                data: all[2],
                initialSort: [
                  {column: "output_field_key", dir: "asc"},
                ],
              }}
              columns={createTransactionTranslationFieldTranslationColumns(all[3])} />
            <ReactTabulator
              options={{
                data: all[0],
                initialSort: [
                  {column: "priority", dir: "asc"},
                  {column: "transaction_translation_id", dir: "asc"},
                ],
              }}
              columns={createTransactionTranslationColumns(all[1])} />
          </div>
        )}
      </Await>
    </Suspense>
  );
}

export default ParsingConfigCreateProcess;
