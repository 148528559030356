import React, {Suspense} from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import {createDollarFormatter} from './TableUtils.js';
import './TableUtils.css';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

const indentOf = (str) => {
  var result = 0;
  for (var i=0; i<str.length; i++) {
    if (str.charAt(i) === ' ') {
      result++;
    } else {
      break;
    }
  }
  return result;
}

const prevIndent = (indent) => {
  return indent - 4;
}

const treeify = (flat) => {
  var lastOfIndent = {};
  var result = [];
  var lastIndent = -1;
  flat.forEach((row) => {
    const indent = indentOf(row['spending_category']);
    if (indent == 0) {
      result.push(row);
    } else {
      const parent = lastOfIndent[prevIndent(indent)];
      if (!parent['_children']) {
        parent['_children'] = [];
      }
      parent['_children'].push(row);
    }
    lastOfIndent[indent] = row;
    lastIndent = indent;
  });
  console.log(result);
  return result;
}

const getExpenses = async () => {
  const url = (process.env.NODE_ENV === 'development' ? '/expenses.json' : '/expenses');
  return fetch(url).then((res) => res.json()).then((res) => treeify(res)).catch((e) => {console.error(e);});
}

export const loader = async ({ params }) => {
  const expenses = getExpenses();
  return defer({expenses});
}

function Spending() {
  const loaderData = useLoaderData();
  
  return (
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.expenses}
             errorElement={<div>Error Loading</div>}>
        {(expenses) => (
          <div className="spending">
            <ReactTabulator
              options={{
                data: expenses,
                dataTree: true,
                dataTreeStartExpanded: [true, false],
                responsiveLayout: "hide",
              }}
              columns = {[
                { title: "Category", field: "spending_category", responsive: 0, minWidth: 130, },
                { title: '$', field: 'value_usd', responsive: 0, formatter: createDollarFormatter(0), hozAlign:'right', minWidth: 95, },
              ]}
            />
          </div>
        )}
      </Await>
    </Suspense>
  );
}

export default Spending;
