import './LoadingScreen.css';
import { TailSpin } from 'react-loader-spinner'

function LoadingScreen({visible}) {
  return (
    <div role="status" className="loading-screen" style={{visibility: (visible ? 'visible' : 'hidden')}}>
      <span className="loading-text">Loading...</span>
      <TailSpin
        height="30%"
        width="30%"
        wrapperClass="loading-animation"
      />
    </div>
  );

  //return (<div style={{visibility: (visible ? 'visible' : 'hidden')}}>Loading</div>);
}

export default LoadingScreen;
