import React, { useState } from 'react';
import LoadingScreen from './LoadingScreen';

function Problems() {
  const [loading, setLoading] = useState(false);
  
  return (
    <div className="problems">
      <LoadingScreen visible={loading} />
    </div>
  );
}

export default Problems;
