import './Portfolio.css';
import LoadingScreen from './LoadingScreen';

import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie'
import { Chart } from "react-google-charts";

function Portfolio() {
  var today = new Date().toLocaleString('sv', { timeZone: 'America/New_York' }).substring(0, 10);
  const minDate = '2007-01-01';
  const maxDate = '2030-01-01';

  const [cookies] = useCookies(['session_id'])

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('2024-01-01');
  const [endDate, setEndDate] = useState(today);
  const [mode, setMode] = useState(cookies['session_id'] ? 'category' : 'allocation_pct');
  const [portfolio, setPortfolio] = useState(cookies['session_id'] ? 0 : 1);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [colors, setColors] = useState(undefined);

  const hasColor = (colors) => {
    for (var i=0; i<colors.length; i++) {
      if (colors[i] !== '#000000') {
        return true;
      }
    }
    return false;
  }

  const updateData = () => {
    const datePattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    if (!datePattern.test(startDate) || !datePattern.test(endDate)) {
        console.log("Bad date");
        return;
    }
    var duration = Math.abs(new Date(startDate.replace(/-/g,'/')) - new Date(endDate.replace(/-/g,'/')));
    duration /= (1000 * 86400);
    var resolution;
    if (duration <= 365) {
      resolution = 'day';
    } else if (duration <= 365*5) {
      resolution = 'week';
    } else {
        resolution = 'month';
    }
    setLoading(true);
    var loaded = 0;
    const tableUrl = process.env.NODE_ENV === 'development' ? '/table.json' : '/table?portfolio=' + portfolio + '&mode=' + mode;
    fetch(tableUrl)
      .then(res => res.json())
      .then((response) => {setTableData(response)})
      .catch((e) => {console.error(e);})
      .finally(() => {if (++loaded === 2) setLoading(false); });
    const graphUrl = process.env.NODE_ENV === 'development' ? '/graph.json' : '/graph?portfolio=' + portfolio + '&start=' + startDate + '&end=' + endDate + '&resolution=' + resolution + '&mode=' + mode;
    fetch(graphUrl)
      .then(res => res.json())
      .then((response) => {setChartData(response.data); setColors(response.colors.length > 0 && hasColor(response.colors) ? response.colors : undefined); })
      .catch((e) => {console.error(e);})
      .finally(() => {if (++loaded === 2) setLoading(false); });
  };

  useEffect(() => {
    updateData();
  }, [startDate, endDate, mode, portfolio]);
  
  const formatters = [
    {
      type: "NumberFormat",
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
    {
      type: "NumberFormat",
      column: 2,
      options: {
        fractionDigits: 2,
        suffix: '%'
      }
    }
  ];

  const renderLoggedInInputs = () => {
    if (cookies['session_id']) {
      return <>                                                                                                                                                                                                                                                                                                                                                                                                        
               <select className="select-portfolio-mode" value={mode} onChange={(e)=>setMode(e.target.value)}>
                 <option value="allocation_pct">allocation_pct</option>
                 <option value="category">category</option>
                 <option value="portfolio">portfolio</option>
                 <option value="account">account</option>
                 <option value="account_symbol">account_symbol</option>
                 <option value="symbol">symbol</option>
               </select>
      
               <select className="select-portfolio" value={portfolio} onChange={(e)=>setPortfolio(e.target.value)}>
                 <option value="0">Both</option>
                 <option value="1">Jack</option>
                 <option value="2">Anne</option>
               </select>
             </>;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <LoadingScreen visible={loading} />

      <div className="portfolio-content">
        <div className="portfolio-input">
          <div>
            {renderLoggedInInputs()}
            <div className="portfolio-input-dates">
              <input aria-label="Date from" value={startDate} max={endDate} min={minDate} type="date" onChange={e => setStartDate(e.target.value)} />
              <input aria-label="Date to" value={endDate} max={maxDate} min={startDate} type="date" onChange={e => setEndDate(e.target.value)} />
            </div>
          </div>
        </div>

        <div className="portfolio-chart">
          <div className="portfolio-chart-holder">
            <Chart
              chartType="ColumnChart"
              options={{
                  title: 'Portfolio',
                  isStacked: true,
                  seriesType: 'bars',
                  series: {0: {type: 'line'}},
                  vAxis: {title: ((mode === 'allocation_pct') ? '%' : 'USD')},
                  hAxis: {title: 'Date'},
                  bar: {
                      groupWidth: '100%'
                  },
                  colors: colors,
                  height:"100%"
              }}
              data={chartData}
            />
          </div>
        </div>
        <div className="portfolio-table">
          <Chart
            chartType="Table"
            options={{
                showRowNumber: false,
                width: '100%',
                allowHtml: true
            }}
            data={tableData}
          />
        </div>

      </div>            
        	  
    </>
  );
}

export default Portfolio;
