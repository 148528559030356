import React, { useState, Suspense } from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';
import {createPercentFormatter, createDollarFormatter, createNumberFormatter} from './TableUtils.js';
import './TableUtils.css';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

const getAccounts = async () => {
  const url = (process.env.NODE_ENV === 'development' ? '/current_account_asset_values_usd.json' : '/current_account_asset_values_usd');
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
}

export const loader = async ({ params }) => {
  const accounts = getAccounts();
  return defer({accounts});
}

function Accounts() {
  const loaderData = useLoaderData();
  
  const [loading, setLoading] = useState(false);
  
  return (
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.accounts}
             errorElement={<div>Error Loading</div>}>
        {(accounts) => (
          <div className="accounts">
            <LoadingScreen visible={loading} />
            <ReactTabulator
              options={{
                data: accounts,
                dataTree:true,
                dataTreeStartExpanded: [true, true, false],
                responsiveLayout: "hide",
              }}
             columns={[
                { title: "Account", field: "account", responsive: 0, minWidth: 130, },
                { title: 'Symb.', field: 'symbol', responsive: 0, minWidth: 35, },
                { title: '#', field: 'quantity', responsive: 5, formatter: createNumberFormatter(2), hozAlign:'right', minWidth: 100, },
                { title: '$', field: 'asset_value_usd', responsive: 0, formatter: createDollarFormatter(0), hozAlign:'right', minWidth: 95, },
                { title: 'wΔ', field: 'd7_change', responsive: 2, formatter: createPercentFormatter(1), hozAlign:'right', minWidth: 55, },
                { title: 'mΔ', field: 'd30_change', responsive: 3, formatter: createPercentFormatter(1), hozAlign:'right', minWidth: 55, },
                { title: 'yΔ', field: 'd365_change', responsive: 4, formatter: createPercentFormatter(1), hozAlign:'right', minWidth: 55, },
              ]}
            />
          </div>
        )}
      </Await>
    </Suspense>
  );
}

export default Accounts;
