export const formatNumber = (number, decimals) => {
  number = Number(number);
  number = number.toFixed(decimals) + '';
  var x = number.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1,$2');
  }
  return x1 + x2;
}

export const createPercentFormatter = (decimals) => {
  return (cell, formatterParams, onRendered) => {
    var content = document.createElement("span");
    var value = cell.getValue();
    var className;
    if (value > 0) {
      className = 'pct_pos';
    } else if (value < 0) {
      className = 'pct_neg';
    } else {
      className = 'pct_zero';
    }
    content.classList.add(className);
    content.innerHTML = formatNumber(value, decimals) + '%';
    return content;
  };
}

export const createDollarFormatter = (decimals) => {
  return (cell, formatterParams, onRendered) => {
    var content = document.createElement("span");
    var value = cell.getValue();
    var className;
    if (value > 0) {
      className = 'dol_pos';
    } else if (value < 0) {
      className = 'dol_neg';
    } else {
      className = 'dol_zero';
    }
    content.classList.add(className);
    content.innerHTML = '$' + formatNumber(value, decimals);
    return content;
  };
}

export const createNumberFormatter = (decimals) => {
  return (cell, formatterParams, onRendered) => {
    var content = document.createElement("span");
    var value = cell.getValue();
    content.innerHTML = formatNumber(value, decimals);
    return content;
  };
}

export const createLinkFormatter = () => {
  return (cell, formatterParams, onRendered) => {
    var content = document.createElement("a");
    var value = cell.getValue();
    content.innerHTML = value;
    return content;
  };
}


export const minMaxFilterFunction = (headerValue, rowValue, rowData, filterParams) => {
  //headerValue - the value of the header filter element
  //rowValue - the value of the column in this row
  //rowData - the data for the row being filtered
  //filterParams - params object passed to the headerFilterFuncParams property
  
  if (rowValue) {
    if (headerValue.start !== "") {
      if (headerValue.end !== "") {
        return rowValue >= headerValue.start && rowValue <= headerValue.end;
      } else {
        return rowValue >= headerValue.start;
      }
    } else {
      if (headerValue.end !== "") {
        return rowValue <= headerValue.end;
      }
    }
  }
  
  return true; //must return a boolean, true if it passes the filter.
}

export const minMaxFilterEditor = (cell, onRendered, success, cancel, editorParams) => {
  var end;
  var container = document.createElement("span");
  
  //create and style inputs
  var start = document.createElement("input");
  start.setAttribute("type", "number");
  start.setAttribute("placeholder", "Min");
  start.setAttribute("min", 0);
  start.setAttribute("max", 100);
  start.style.padding = "4px";
  start.style.width = "50%";
  start.style.boxSizing = "border-box";
  
  start.value = cell.getValue();
  
  function buildValues(){
    success({
      start:start.value,
      end:end.value,
    });
  }
  
  function keypress(e){
    if(e.keyCode === 13){
      buildValues();
    }
    
    if(e.keyCode === 27){
      cancel();
    }
  }
  
  end = start.cloneNode();
  end.setAttribute("placeholder", "Max");
  
  start.addEventListener("change", buildValues);
  start.addEventListener("blur", buildValues);
  start.addEventListener("keydown", keypress);
  
  end.addEventListener("change", buildValues);
  end.addEventListener("blur", buildValues);
  end.addEventListener("keydown", keypress);
  
  container.appendChild(start);
  container.appendChild(end);
  
  return container;
}
