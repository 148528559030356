import React, { useState, Suspense } from 'react';
import { defer, useLoaderData, Await, Navigate } from "react-router-dom";
import LoadingScreen from './LoadingScreen';

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

import {createLinkFormatter} from './TableUtils.js';

const tablify = (data) => {
  const result = [];
  data.forEach((datum) => {
    result.push({"create_process": datum});
  });
  return result;
};

const getCreateProcesses = async () => {
  const url = (process.env.NODE_ENV === 'development' ? '/transaction_translation_create_processes.json' : '/transaction_translation_create_processes');
  return fetch(url).then((res) => res.json()).then((res) => tablify(res)).catch((e) => {console.error(e);});
};

export const loader = async ({ params }) => {
  const createProcesses = getCreateProcesses();
  return defer({createProcesses});
};

function ParsingConfigCreateProcessList() {
  const loaderData = useLoaderData();
  
  const [loading, setLoading] = useState(false);
  const [createProcess, setCreateProcess] = useState(null);

  const columns = [
    { title: "create_process", field: "create_process", responsive: 0, formatter: createLinkFormatter(), cellClick:function(e, cell) { setCreateProcess( cell.getRow().getData().create_process );}, },
  ];
  
  return (
    createProcess ? <Navigate to={`/parsing/${createProcess}`} /> :
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.createProcesses}
             errorElement={<div>Error Loading</div>}>
        {(createProcesses) => (
          <div className="parsing-config-create-processes">
            <LoadingScreen visible={loading} />
            <ReactTabulator
              options={{
                data: createProcesses
              }}
              columns={columns} />
          </div>
        )}
      </Await>
    </Suspense>
  );
}

export default ParsingConfigCreateProcessList;
