import './Transactions.css';
import React, { useState, Suspense } from 'react';
import LoadingScreen from './LoadingScreen';
import {createLinkFormatter, createNumberFormatter, minMaxFilterEditor, minMaxFilterFunction} from './TableUtils.js';
import { useSearchParams, defer, Await, Navigate, useLoaderData } from "react-router-dom";

import 'react-tabulator/lib/styles.css';
import 'react-tabulator/lib/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'

const getRanges = async () => {
  const url = (process.env.NODE_ENV === 'development' ? '/ranges.json': '/ranges');
  return fetch(url).then((res) => res.json()).catch((e) => {console.error(e);});
}

export const loader = async ({ params }) => {
  const ranges = getRanges();
  return defer({ranges});
}

function searchParamsToFilter(searchParams) {
  const filters = {};
  searchParams.forEach((value, key) => {
    if (key === "id" || key === "note") {
      filters[key] = {field: key, value: value, type: "like"};
    } else if (key === "portfolio_id" || key === "account" || key === "symbol" || key === "create_process" || key === "type" || key === "recording_account" || key === "other_account" || key === "other_symbol" || key === "day") {
      filters[key] = {field: key, value: value, type: "="};
    } else if (key === "min_quantity" || key === "min_other_quantity") {
      const newKey = key.substring(4);
      const newValue = filters[newKey] ? filters[newKey] : {field: newKey, value: {}};
      newValue.value.start = value;
      filters[newKey] = newValue;
    } else if (key === "max_quantity" || key === "max_other_quantity") {
      const newKey = key.substring(4);
      const newValue = filters[newKey] ? filters[newKey] : {field: newKey, value: {}};
      newValue.value.end = value;
      filters[newKey] = newValue;
    }
  });
  const result = [];
  for (var key in filters) {
    const value = filters[key];
    result.push(value);
  };
  return result;
}

function filterToSearchParams(filter) {
  const result = {};
  filter.forEach((row) => {
    const key = row.field;
    if (key === "id" || key === "note" || key === "portfolio_id" || key === "account" || key === "symbol" || key === "create_process" || key === "type" || key === "recording_account" || key === "other_account" || key === "other_symbol" || key === "day") {
      result[key] = row.value;
    } else if (key === "quantity" || key === "other_quantity") {
      if (row.value['start']) {
        result["min_" + key] = row.value['start'];
      }
      if (row.value['end']) {
        result["max_" + key] = row.value['end'];
      }
    }
  });
  return result;
}

function Transactions() {
  const loaderData = useLoaderData();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [loading, setLoading] = useState(false);

  const columns = (ranges) => {return [
    { title: "portfolio_id", field: "portfolio_id", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams: { values: ranges['portfolios'].map((v) => {return v['portfolio_id']}), clearable:true}, },
    { title: "account", field: "account", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['accounts'], clearable:true}, formatter: createLinkFormatter(), },
    { title: "symbol", field: "symbol", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['symbols'], clearable:true}, formatter: createLinkFormatter(), },
    { title: "day", field: "day", headerSortStartingDir:"desc", sorter:"date",  headerFilter:'date'},
    { title: "quantity", field: "quantity", sorter:"number", headerFilter:minMaxFilterEditor, headerFilterFunc:minMaxFilterFunction, headerFilterLiveFilter:false, formatter: createNumberFormatter(2), hozAlign:'right', },
    { title: "other_account", field: "other_account", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['accounts'], clearable:true}, formatter: createLinkFormatter(), },
    { title: "other_symbol", field: "other_symbol", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['symbols'], clearable:true}, formatter: createLinkFormatter(), },
    { title: "other_quantity", field: "other_quantity", headerFilter:minMaxFilterEditor, headerFilterFunc:minMaxFilterFunction, headerFilterLiveFilter:false, formatter: createNumberFormatter(2), hozAlign:'right', },
    { title: "recording_account", field: "recording_account", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['accounts'], clearable:true}, formatter: createLinkFormatter(), },
    { title: "type", field: "type", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['transaction_types'], clearable:true}, },
    { title: "id", field: "id", headerFilter:"input", },
    { title: "create_process", field: "create_process", headerFilter:'list', headerFilterPlaceholder: "-- Select --", headerFilterParams:{values:ranges['create_processes'], clearable:true}, formatter: createLinkFormatter(), },
    { title: "create_date", field: "create_date", },
    { title: "update_date", field: "update_date", },
    { title: "note", field: "note", headerFilter:"input", },
  ]};

  const initialFilter = searchParamsToFilter(searchParams);

  const options = {
    ajaxURL: (process.env.NODE_ENV === 'development' ? '/transactions.json' : '/transactions'),
    ajaxResponse: (url, params, response) => { return response },
    ajaxError: function (error) { console.log('ajaxError', error); },
    pagination: true,
    paginationMode: 'remote',
    sortMode: 'remote',
    initialSort:[
      {column:"day", dir:"desc"},
      {column:"id", dir:"asc"}
    ],
    filterMode: 'remote',
    initialHeaderFilter: initialFilter,
    layout:"fitDataStretch",
    height:"100%",
  };

  const dataFilteredHandler = (filters, rows) => {
    const newSearchParams = filterToSearchParams(filters);
    setSearchParams(newSearchParams);
  };

  const renderTable = (ranges) => {
    return <ReactTabulator
             options={options}
             columns={columns(ranges)}
             events={{dataFiltered: dataFilteredHandler}}
           />;
  }
  
  return (
    <Suspense fallback={<LoadingScreen visible="true" />}>
      <Await resolve={loaderData.ranges}
             errorElement={<div>Error Loading</div>}>
        {(ranges) => (
          <div className="transactions">
            <LoadingScreen visible={loading} />
            { renderTable(ranges) }
          </div>
        )}
      </Await>
    </Suspense>
  );

}

export default Transactions;
