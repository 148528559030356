import React, { useState } from 'react';
import LoadingScreen from './LoadingScreen';

function Settings() {
  const [loading, setLoading] = useState(false);
  
  return (
    <div className="settings">
      <LoadingScreen visible={loading} />
    </div>
  );
}

export default Settings;
