import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './index.css';
import ErrorPage from "./ErrorPage";
import Finance from './Finance';
import Accounts, {loader as accountsLoader} from './Accounts';
import Portfolio from './Portfolio';
import Transactions, {loader as transactionsLoader} from './Transactions';
import Spending, {loader as spendingLoader} from './Spending';
import TransactionFilePathChooser, {loader as transactionFilePathChooserLoader} from './TransactionFilePathChooser';
import TransactionFileChooser, {loader as transactionFileChooserLoader} from './TransactionFileChooser';
import TransactionFileDetail, {loader as transactionFileDetailLoader} from './TransactionFileDetail';
import ParsingConfigCreateProcessList, {loader as parsingConfigCreateProcessListLoader} from './ParsingConfigCreateProcessList';
import ParsingConfigCreateProcess, {loader as parsingConfigCreateProcessLoader} from './ParsingConfigCreateProcess';
import Problems from './Problems';
import Settings from './Settings';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Finance />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Accounts />,
        loader: accountsLoader,
      },
      {
        path: "/accounts",
        element: <Accounts />,
        loader: accountsLoader,
      },
      {
        path: "/charts",
        element: <Portfolio />,
      },
      {
        path: "/transactions",
        element: <Transactions />,
        loader: transactionsLoader,
      },
      {
        path: "/spending",
        element: <Spending />,
        loader: spendingLoader,
      },
      {
        path: "/file/:rawFileRecordId",
        element: <TransactionFileDetail />,
        loader: transactionFileDetailLoader,
      },
      {
        path: "/files/:pathPrefix",
        element: <TransactionFileChooser />,
        loader: transactionFileChooserLoader,
      },
      {
        path: "/files",
        element: <TransactionFilePathChooser />,
        loader: transactionFilePathChooserLoader,
      },
      {
        path: "/parsing",
        element: <ParsingConfigCreateProcessList />,
        loader: parsingConfigCreateProcessListLoader,
      },
      {
        path: "/parsing/:createProcess",
        element: <ParsingConfigCreateProcess />,
        loader: parsingConfigCreateProcessLoader,
      },
      {
        path: "/problems",
        element: <Problems />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
