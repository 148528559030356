import './Finance.css';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie'
import { Outlet, Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PieChartIcon from '@mui/icons-material/PieChart';
import FolderIcon from '@mui/icons-material/Folder';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';

function Finance() {
  const [cookies] = useCookies(['session_id']);
  const [isMenuOpen, setMenuOpen] = useState(false);
  
  const renderLoggedInAppBarContent = () => {
    if (cookies['session_id']) {
      return <IconButton
               size="large"
               aria-label="account of current user"
               aria-controls="menu-appbar"
               color="inherit"
             >
               <AccountCircleIcon />
             </IconButton>;
    } else {
      return <></>;
    }
  };

  const renderHamburger = () => {
    if (cookies['session_id']) {
      return <>
               <IconButton 
                 edge="start" 
                 color="inherit" 
                 aria-label="open drawer" 
                 onClick={toggleDrawer(true)}
                 sx={{ 
                   mr: 2,
                   //display: {
                   //  xs: 'block',
                   //  sm: 'none',
                   //}
                 }}
               >
                 <MenuIcon />
               </IconButton>
               <Drawer
                 open={isMenuOpen}
                 onClose={toggleDrawer(false)}
               >
                 <Box sx={{
                        p: 2,
                        height: 1,
                        backgroundColor: "#dbc8ff",
                      }}>
                   <IconButton sx={{mb: 2}} onClick={toggleDrawer(false)}>
                     <CloseIcon />
                   </IconButton>
                   <Divider sx={{mb: 2}} />
                   <Box sx={{mb: 2}}>
                     <ListItemButton component={Link} to={"/accounts"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <AccountBalanceIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Accounts" />
                     </ListItemButton>
                     <ListItemButton component={Link} to={"/charts"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <SsidChartIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Charts" />
                     </ListItemButton>
                     <ListItemButton component={Link} to={"/transactions"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <ReceiptLongIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Transactions" />
                     </ListItemButton>
                     <ListItemButton component={Link} to={"/spending"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <PieChartIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Spending" />
                     </ListItemButton>
                   </Box>
                   <Divider sx={{mb: 2}} />
                   <Box sx={{mb: 2}}>
                     <ListItemButton component={Link} to={"/files"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <FolderIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Files" />
                     </ListItemButton>
                     <ListItemButton component={Link} to={"/parsing"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <AltRouteIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Parsing" />
                     </ListItemButton>
                     <ListItemButton component={Link} to={"/problems"} onClick={() => {setMenuOpen(false)}}>
                       <ListItemIcon>
                         <ErrorIcon sx={{color: "primary.main"}}/>
                       </ListItemIcon>
                       <ListItemText primary="Problems" />
                     </ListItemButton>
                   </Box>
                   <Divider sx={{mb: 2}} />
                   <ListItemButton  component={Link} to={"/settings"} onClick={() => {setMenuOpen(false)}}>
                     <ListItemIcon>
                       <SettingsIcon />
                     </ListItemIcon>
                     <ListItemText primary="Settings" />
                   </ListItemButton>
                 </Box>
               </Drawer>
             </>;
    } else {
      return <></>;
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuOpen(open);
  };

  return (
    <>
      <div id="finance">
        <AppBar position="static">
          <Toolbar>
            { renderHamburger() }
            <Typography variant="h6" component="div" sx={{flexGrow: 1, fontWeight: 700}}>
              finance.jackohare.com
            </Typography>
            <Typography variant="subtitle1">
              {process.env.NODE_ENV === 'production' ? '' : process.env.NODE_ENV}
            </Typography>
            <Typography variant="subtitle1">
              {renderLoggedInAppBarContent()}
            </Typography>
          </Toolbar>
        </AppBar>
        <div id="finance-content">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Finance;
